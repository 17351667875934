import React from "react";
import Page from "../components/Page";
import NewsNav from "../components/NewsNav";
import EmailSubscription from "./../components/EmailSubscription";
import WithSideNav from "../components/WithSideNav";
import SEO from "../components/SEO";
import Section from "../components/Section";

function NewsPage() {
  const sideNavProps = [
    {
      title: "Collaborate",
      link: "/collaborate"
    },
    {
      title: "News & Updates",
      link: "/news"
    },
    {
      title: "Roadmap",
      link: "/roadmap"
    }
  ];

  return (
    <Page>
      <SEO title="News | OHIF" />
      <Section>
        <WithSideNav sideNavProps={sideNavProps} title="News">
          <div className="mr-4">
            <p className="my-5">
              Stay updated by subscribing to our newsletter. You'll receive
              information on the latest releases, newly added functionality, and
              valuable resources.
            </p>
            <EmailSubscription />
            <div className="text-lg truncate mt-5">
              <NewsNav />
            </div>
          </div>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default NewsPage;
